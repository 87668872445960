<template>
  <!-- 消息管理 - 消息列表-详情 -->
  <div class="pageContol newsListInfo">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.push('/web/newsList')">消息管理</a>
          <i class="el-icon-arrow-right"></i>
          <a href="javascript:;" class="cur-a">消息列表-详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <p class="p1">{{ newsDataInfo.msgName }}</p>
        <p class="p2">{{ newsDataInfo.createTime }}</p>
        <p class="p3">
          {{ newsDataInfo.msgContent }}
          <span
            class="ljsh"
            v-show="classInfo.projectId"
            @click="auditNow"
          >[立即审核]</span>
        </p>
        <el-button type="primary" class="bgc-bv" size="small" @click="returnPreviousPage">返回消息中心</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newsListInfo",
  data() {
    return {
      // 消息详情数据
      newsDataInfo: {},
      // 班级信息
      classInfo: {}
    };
  },
  mounted() {},
  computed: {},
  watch: {
    // // 监听 - websocketData
    // "$route.query.newsInfo": {
    //   handler(newName, oldName) {
    //     if (newName) {
    //       this.$router.push('/web/newsListInfo');
    //     }
    //   },
    //   // 立即先去执行里面的handler方法 - 类似初始化就执行
    //   // immediate: true,
    //   // 开启深度监听
    //   deep: true
    // }
  },
  created() {
    this.getNewsDataInfo();
    if (this.newsDataInfo.projectId) {
      this.getClassState();
    }
  },
  methods: {
    // 返回上一页面
    returnPreviousPage() {
      this.$router.push({ path: "/web/newsList" });
    },
    // 立即审核
    auditNow() {
      this.$router.push({
        path: "/web/ClassReviewDetails",
        query: {
          getClassInfo: JSON.stringify({
            projectId: this.classInfo.projectId,
            startStudy: this.classInfo.startStudy,
            currentAuditState: this.classInfo.auditState
          })
        }
      });
    },
    // 初始化 - 获取消息列表传过来的数据
    getNewsDataInfo() {
      this.newsDataInfo = JSON.parse(this.$route.query.newsInfo);
      let pushData = {
        ids: [Number(this.newsDataInfo.msgId)],
        msgState: "30"
      };
      this.$post("/message/batchUpdateMagState", pushData).then(res => {});
    },
    // 初始化 - 获取消息列表传过来的newsDataInfo.projectId 获取班级状态
    getClassState() {
      this.$post("/message/getProjectStateByProjectId ", {
        projectId: this.newsDataInfo.projectId
      }).then(res => {
        this.classInfo = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.newsListInfo {
  .framePage {
    .framePage-body {
      .p1 {
        line-height: 25px;
        margin-left: 20px;
      }
      .p2 {
        font-size: 12px;
        color: #909399;
        margin-left: 20px;
      }
      .p3 {
        line-height: 35px;
        border-top: 1px solid #c0c4cc;
        margin-top: 20px;
        margin-left: 20px;
        .ljsh {
          color: #409eff;
          font-size: 12px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .el-button {
        width: 120px;
        margin-left: 20px;
        margin-top: 40px;
      }
    }
  }
}
</style>